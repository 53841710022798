import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" pathname="/about" mdxType="SEO" />
    <h2>{`DVLPR® is`}</h2>
    <p>{`The awesome developers who love what they are doing.`}</p>
    <p>{`The people who are just starting to code. (Welcome, it’s a fun journey!)`}</p>
    <p>{`The people who are constantly learning new and fun things.`}</p>
    <p>{`The people who constantly hit bug after bug, but are excited when the bug has changed. (I can’t be the only one
right?)`}</p>
    <p>{`The open source devs who are constantly trying their hardest and have
to keep fighting off Github comment trolls.`}</p>
    <p>{`The indie hackers who are making
their own products wherever they want to.`}</p>
    <p>{`The freelance developers making awesome sites for their clients.`}</p>
    <p>{`Those who know it’s not a great idea to push to production on a Friday afternoon.`}</p>
    <p>{`The people who love to code, maybe too much, and keep making side projects while your wife says that you should probably find
a new hobby.`}</p>
    <p><strong parentName="p">{`We are all DVLPRs, except gatekeepers, fuck them.`}</strong></p>
    <p>{`DVLPR is a one-man shop, run by `}<a href="https://twitter.com/TravisWerbelow" target="_blank" rel="noopener noreferrer">{`Travis Werbelow`}</a>{`.`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "https://cdn.shopify.com/s/files/1/0711/1377/files/Me_holding_Coffee_Cup_BW_large.JPG?v=1524665490",
        "alt": "Travis Werbelow DVLPR® Founder"
      }}></img></p>
    <br />
    <p>{`If you like what DVLPR offers, or know someone who might, please share with your
friends and co-workers.`}</p>
    <p>{`If you have any feedback, questions, comments or whatever, feel free to email me
at travis `}{`[at]`}{` dvlpr.io`}</p>
    <p><strong parentName="p">{`Happy Coding`}</strong>{`,`}</p>
    <p>{`Travis`}</p>
    <br />
    <br />
    <p>{`DVLPR is a registered trademark.
All designs © DVLPR, LLC`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      